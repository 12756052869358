import Keycloak from "keycloak-js/lib/keycloak.js";
import React, { Suspense, useEffect, useState, createContext } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import Axios from "axios";

import Routers from "./components/Routers";

import global from "./translations/global.json";
import adminGlobal from "./translations/admin/global.json";
import Loading from "./components/admin/Loading";
import { getLocaleByShortcut } from "./utils/antdLocale.jsx";
import { ConfigProvider } from "antd";
import { RouterEnum } from "./utils/routers";

const LayoutAdmin = React.lazy(() => import("./components/admin/LayoutAdmin"));
const Themes = {
  light: `${process.env.PUBLIC_URL}/css/antd.css`,
  dark: `${process.env.PUBLIC_URL}/css/antd.dark.css`,
};

export const AuthContext = createContext(null);

function App(props) {
  const language = useSelector((state) => state.language.language);
  const theme = useSelector((state) => state.userSettings.theme);
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const combinedTranslation = {
    en: {
      ...global["en"],
      ...adminGlobal["en"],
    },
    sk: {
      ...global["sk"],
      ...adminGlobal["sk"],
    },
  };

  useEffect(() => {
    if (location.pathname.includes("www"))
      history.push(location.pathname.replace("www.", ""));

    if (keycloak == null) {
      Axios.get(`api/keycloak/config`)
        .then((response) => {
          const keycloak = new Keycloak({ ...response.data });
          setKeycloak(keycloak);

          keycloak
            .init({ onLoad: "login-required", checkLoginIframe: false })
            .then((authenticated) => {
              setAuthenticated(authenticated);

              if (authenticated) {
                localStorage.setItem("token", keycloak.token);
              } else {
                keycloak.login();
              }
            })
            .catch(() => {
              console.error("Keycloak initialization failed");
            });
        })
        .catch((exception) => {
          if (exception.response?.status === 400) window.location.reload();

          if (exception.response?.status === 401) window.location.reload();

          if (exception.response?.status === 403)
            window.location = `${RouterEnum.Forbidden.Path}`;
        });
    }
  }, []);

  useEffect(() => {
    if (authenticated && keycloak) {
      const refreshTokenInterval = setInterval(() => {
        keycloak
            .updateToken(300) //if the token is valid for less than 5 minutes, it will be refreshed
          .then((refreshed) => {
            if (refreshed) {
              localStorage.setItem("token", keycloak.token);
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
            keycloak.logout();
          });
      }, 240000); //try to refresh every 4 minutes

      return () => clearInterval(refreshTokenInterval);
    }
  }, [authenticated, keycloak]);

  if (keycloak == null) return null;

  return (
    <AuthContext.Provider value={{ keycloak, authenticated }}>
      <IntlProvider
        key={language}
        locale={language}
        messages={combinedTranslation[language]}
      >
        <ThemeSwitcherProvider themeMap={Themes} defaultTheme={theme}>
          <Suspense fallback={<Loading />}>
            <ConfigProvider locale={getLocaleByShortcut(language)}>
              <LayoutAdmin>
                <Suspense fallback={<Loading />}>
                  {authenticated ? <Routers /> : <Loading />}
                </Suspense>
              </LayoutAdmin>
            </ConfigProvider>
          </Suspense>
        </ThemeSwitcherProvider>
      </IntlProvider>
    </AuthContext.Provider>
  );
}

export default App;
