import React, { Component, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AuthContext } from "./../App";

import { RouterEnum } from "../utils/routers";

const ProtectedRoute = (children) => {
  const { keycloak } = useContext(AuthContext);
  const { component: Component, ...rest } = children;
  const isLoggedIn = keycloak.authenticated;
    let intersection = children.requiredRoles.filter((rr) =>
    keycloak.hasRealmRole(rr)
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn && intersection.length > 0) {
          return <Component {...props} />;
        } else {
          return <Redirect to={RouterEnum.Forbidden.Path} />;
        }
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapDispatchToProps)(ProtectedRoute);
